import React from 'react';
import { getImageUrl } from 'helper/helper';

const OldFinalists = () => {
	return (
		<div className="finalists mt-5">
			<div className="title theme-color">2021 FINALISTS</div>
			<p>
				ANZIIF is delighted to announce the finalists for the 17th Australian
				Insurance Industry Awards.
			</p>
			<table>
				<tr>
					<td>SMALL BROKING COMPANY OF THE YEAR</td>
					<td>
						Austbrokers Coast to Coast
						<br />
						Carollo Horton {'&'} Associates
						<br />
						Hunter Broking Group
						<br />
						Trade Risk
						<br />
						Vie Legal Insurance
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>MEDIUM BROKING COMPANY OF THE YEAR</td>
					<td>
						Guardian Insurance Brokers
						<br />
						Warren Saunders Insurance Brokers
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>
						LARGE BROKING COMPANY OF THE YEAR
						<br />
						<span className="sponsored-by">Sponsored by:</span>
						<br />
						<a href="https://www.qbe.com/au" target="_blank" rel="noreferrer">
							<img
								src={getImageUrl('/assets/images/sponsors/QBE.jpg')}
								alt="QBE"
							/>
						</a>
					</td>
					<td>
						Aon
						<br />
						Gallagher
						<br />
						Honan Insurance Group
						<br />
						Insurance Advisernet
						<br />
						Insurance House Group
						<br />
						Marsh
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>AUTHORISED REPRESENTATIVE NETWORK OF THE YEAR</td>
					<td>
						Community Broker Network
						<br />
						Insurance Advisernet
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>
						UNDERWRITING AGENCY OF THE YEAR <br />{' '}
						<span className="sponsored-by">Sponsored by:</span>
						<br />
						<a href="https://uac.org.au/" target="_blank" rel="noreferrer">
							<img
								src={getImageUrl('/assets/images/sponsors/UAC.jpg')}
								alt="UAC"
							/>
						</a>
					</td>
					<td>
						Agile Underwriting Services <br /> CHU Underwriting Agencies <br />
						Club Marine Insurance <br /> GT Insurance <br /> ProRisk <br />
						Sportscover <br /> Underwriting Agencies of Australia
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>
						SMALL GENERAL INSURANCE COMPANY OF THE YEAR
						<br />
						<span className="sponsored-by">Sponsored by:</span>
						<br />
						<a
							href="https://solutions.jbhifi.com.au/solutions/"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src={getImageUrl('/assets/images/sponsors/Solutions.jpg')}
								alt="JB HI-FI SOLUTIONS"
							/>
						</a>
					</td>
					<td>
						Adica Insurance <br />
						Guild Insurance <br /> Lawcover Insurance <br /> RACT Insurance
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>
						LARGE GENERAL INSURANCE COMPANY OF THE YEAR
						<br />
						<span className="sponsored-by">Sponsored by:</span>
						<br />
						<a
							href="https://www.steadfast.com.au/"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src={getImageUrl('/assets/images/sponsors/Steadfast.jpg')}
								alt="Steadfast"
							/>
						</a>
					</td>
					<td>
						Allianz <br />
						CGU <br /> Hollard Insurance <br /> QBE Australia Pacific <br />
						Zurich, General Insurance (Australia {'&'} New Zealand)
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>LIFE INSURANCE COMPANY OF THE YEAR</td>
					<td>
						AIA Australia <br /> MetLife Australia <br /> TAL
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>
						INSURTECH OF THE YEAR
						<br />
						<span className="sponsored-by">Sponsored by:</span>
						<br />
						<a href="www.sedgwick.com/au" target="_blank" rel="noreferrer">
							<img
								src={getImageUrl('/assets/images/sponsors/Sedgwick.jpg')}
								alt="SEDGWICK"
							/>
						</a>
					</td>
					<td>
						Codafication <br /> Daisee <br /> Mitti Insurance <br /> Wilbur
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>PROFESSIONAL SERVICES FIRM OF THE YEAR</td>
					<td>
						Barry.Nilsson.Lawyers <br /> DLA Piper <br />
						Finity <br /> Hall {'&'} Wilcox <br /> MinterEllison <br /> Sedgwick
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>SERVICE PROVIDER TO THE INSURANCE INDUSTRY</td>
					<td>
						Bay Building Group <br /> BizCover <br /> Claim Central <br />{' '}
						Construct Services <br /> Duck Creek Technologies <br /> DXC
						Technology <br /> Gallagher Bassett <br /> IVAA <br /> JB Hi-Fi
						<br />
						Manheim Salvage Solutions <br /> MJM Corporate Risk Services <br />{' '}
						MVR - Motor Vehicle Replacement <br /> Paynters <br /> Phenix
						Jewellery
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>EXCELLENCE IN WORKPLACE DIVERSITY AND INCLUSION</td>
					<td>
						Allianz Australia <br /> CHU Underwriting Agencies <br /> Hollard
						Insurance <br /> Marsh
						<br />
						MetLife Australia <br /> QBE Australia Pacific
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>INSURANCE LEARNING PROGRAM OF THE YEAR</td>
					<td>
						Guild Insurance <br /> Sedgwick
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>YOUNG INSURANCE PROFESSIONAL OF THE YEAR</td>
					<td>
						Renee Betton, CHU Underwriting <br /> Agencies <br /> Robbie Gibbs,
						SRG Group <br />
						Matthew McHutchison, IVAA <br /> Linh Nguyen, Sedgwick <br />{' '}
						Jonathan Pack, Chubb Insurance <br /> Bianca Parussolo, ProRisk{' '}
						<br /> Jessie Wang, Hollard Insurance
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>INSURANCE LEADER OF THE YEAR</td>
					<td>Announced on the day</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>ANZIIF LIFETIME ACHIEVEMENT AWARD</td>
					<td>Announced on the day</td>
				</tr>
			</table>
		</div>
	);
};

export default OldFinalists;
