import React from 'react';
import { getImageUrl } from 'helper/helper';

const Finalists = () => {
	return (
		<div className="finalists mt-5">
			<div className="title theme-color">2021 FINALISTS</div>
			<p>
				ANZIIF is delighted to announce the finalists for the 9th New Zealand
				Insurance Industry Awards.
			</p>
			<table>
				<tr>
					<td>LARGE BROKING COMPANY OF THE YEAR</td>
					<td>Announced on the day</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>UNDERWRITING AGENCY OF THE YEAR</td>
					<td>Announced on the day</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>
						GENERAL INSURANCE COMPANY OF THE YEAR
						<br />
						<span className="sponsored-by">Sponsored by:</span>
						<br />
						<a
							href="https://solutions.jbhifi.com.au/solutions/"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src={getImageUrl('/assets/images/2021_sponsors/Solutions.jpg')}
								alt="JB HI-FI SOLUTIONS"
							/>
						</a>
					</td>
					<td>
						AA Insurance
						<br />
						AIG New Zealand
						<br />
						FMG (Farmers Mutual Groups)
						<br />
						Vero
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>LIFE INSURANCE COMPANY OF THE YEAR</td>
					<td>
						AIA New Zealand
						<br />
						Asteron Life
						<br />
						Fidelity Life
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>
						INNOVATION OF THE YEAR <br />
						<span className="sponsored-by">Sponsored by:</span>
						<br />
						<a
							href="https://www.manheim.co.nz/ "
							target="_blank"
							rel="noreferrer"
						>
							<img
								src={getImageUrl('/assets/images/2021_sponsors/Manheim.jpg')}
								alt="UAC"
							/>
						</a>
					</td>
					<td>
						Gallagher Bassett
						<br />
						Pinnacle Life
						<br />
						Tower
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>PROFESSIONAL SERVICES FIRM OF THE YEAR</td>
					<td>
						DLA Piper <br />
						Finity
						<br />
						McLarens
						<br />
						Sedgwick New Zealand
						<br />
						Wotton + Kearney
						<br />
						RMS
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>SERVICE PROVIDER TO THE INSURANCE INDUSTRY</td>
					<td>
						CoreLogic NZ <br />
						IVAA <br /> JB Hi-Fi <br /> Smith{'&'}Smith® <br />
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>EXCELLENCE IN WORKPLACE DIVERSITY AND INCLUSION</td>
					<td>
						DLA Piper <br /> Suncorp New Zealand <br /> Zurich General
						Insurance, Australia {'&'} New Zealand
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>INSURANCE LEARNING PROGRAM OF THE YEAR</td>
					<td>
						AA Insurance <br /> AIG New Zealand <br />
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>
						YOUNG INSURANCE PROFESSIONAL OF THE YEAR <br />
						<span className="sponsored-by">Sponsored by:</span>
						<br />
						<a
							href="https://www.nzi.co.nz/en.html"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src={getImageUrl('/assets/images/2021_sponsors/NZI.png')}
								alt="NZI"
							/>
						</a>
					</td>
					<td>
						Stephen Cantwell, FMG
						<br />
						Amy Cavanaugh, Pinnacle Life
						<br />
						Len Elikhis, AIA New Zealand
						<br />
						Joseph Fitzgerald, Wotton + Kearney
						<br />
						Steph Kelly, FMG
						<br />
						Daniel Mathieson, Sherpa
						<br />
						Megan Wolak, Delta Insurance
					</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>
						BROKING PROFESSIONAL OF THE YEAR <br />
						<span className="sponsored-by">Sponsored by:</span>
						<br />
						<a href="https://www.vero.co.nz/ " target="_blank" rel="noreferrer">
							<img
								src={getImageUrl('/assets/images/2021_sponsors/Vero.jpg')}
								alt="Vero"
							/>
						</a>
					</td>
					<td>Announced on the day</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>
						INSURANCE LEADER OF THE YEAR <br />
						<span className="sponsored-by">Sponsored by:</span>
						<br />
						<a
							href="https://www.dlapiper.com/en/newzealand"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src={getImageUrl('/assets/images/2021_sponsors/DLA.jpg')}
								alt="DLA Piper"
							/>
						</a>
					</td>
					<td>Announced on the day</td>
				</tr>
				<tr>
					<td colSpan="2">
						<div className="speaker-divider"></div>
					</td>
				</tr>
				<tr>
					<td>
						ANZIIF LIFETIME ACHIEVEMENT AWARD <br />
						<span className="sponsored-by">Sponsored by:</span>
						<br />
						<a
							href="https://www.asteronlife.co.nz/"
							target="_blank"
							rel="noreferrer"
						>
							<img
								src={getImageUrl('/assets/images/2021_sponsors/Asteron.jpg')}
								alt="Asteron"
							/>
						</a>
					</td>
					<td>Announced on the day</td>
				</tr>
			</table>
		</div>
	);
};

export default Finalists;
