import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, useParams, Link, NavLink } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { isLoggedIn } from 'selectors/auth';
import Button from 'react-bootstrap/Button';
import { eventInformation } from 'actions/event';
import PATH from 'routes';
import { Helmet } from 'react-helmet';
import { endDateSplit, SUBSCRIBE_BY_SESSION, sortEvents } from 'util/helper';
import { getContentBlock } from 'selectors/event';
import { validDateTime } from 'util/helper';
import { getImageUrl } from 'helper/helper';

const Information = (props) => {
	const initialState = {
		name: '',
		collections: [],
		loading: true,
		error: '',
		redirect_to_video_session: false,
		display_event_filter: true,
		session_subscribe: 3,
		firstVsId: '',
	};
	let { slug } = useParams();
	const [state, setState] = useState(initialState);
	let isLoggedIn = props.isLoggedIn(slug);

	if (!isLoggedIn) {
		props.history.push('/');
	}
	const { contentBlocks } = props;

	const callbackSuccess = (data) => {
		let {
			redirect_to_video_session,
			slug,
			video_session,
			display_event_filter,
			session_subscribe,
		} = data;
		if (redirect_to_video_session) {
			if (video_session) {
				props.history.push(`/${slug}/watch-now/${video_session}`);
			} else {
				props.history.push(PATH.HOME);
			}
		} else if (!display_event_filter) {
			props.history.push(PATH.HOME);
		} else {
			setState({
				...data,
				loading: false,
				session_subscribe: session_subscribe,
			});
		}
	};

	useEffect(() => {
		let id;
		if (state.collections) {
			state.collections.forEach((data) => {
				let sessions = data.sessions;
				if (sessions) {
					sessions.forEach((session) => {
						let video_sessions = session.video_session;
						let sortedData = video_sessions.sort(sortEvents);
						if (sortedData[0]) {
							id = sortedData[0].id;
						}
						session.video_session = sortedData;
					});
				}
			});
			setState({ ...state, firstVsId: id, collections: state.collections });
		}
	}, [state.collections]);

	const callbackError = () => {
		setState({
			...state,
			loading: false,
			error:
				"We are sorry to say that we don't support this browser. Please try another browser. Sorry for your inconvenience.",
		});
	};

	useEffect(() => {
		if (isLoggedIn) {
			const { eventInformation } = props;
			eventInformation(slug, callbackSuccess, callbackError);
		}
	}, [slug, isLoggedIn]);

	return (
		<div className="event-info all-vs mt-4">
			{state.name && (
				<Helmet>
					<title>
						{contentBlocks.tab_heading ? contentBlocks.tab_heading : state.name}
					</title>
				</Helmet>
			)}
			{state.loading ? (
				<p>Loading sessions...</p>
			) : (
				<div>
					<Row>
						<Col className="my-2">
							<div className="title">
								{contentBlocks.info_heading && contentBlocks.info_heading}
							</div>
						</Col>
					</Row>
					{contentBlocks.above_my_session && (
						<Row>
							<Col>
								<div
									dangerouslySetInnerHTML={{
										__html: contentBlocks.above_my_session,
									}}
								></div>
							</Col>
						</Row>
					)}
					{state.firstVsId && (
						<Link
							to={`/${slug}/watch-now/${state.firstVsId}`}
							className="btn btn-default btn-lg"
						>
							Watch Now
						</Link>
					)}

					{contentBlocks.below_my_session && (
						<Row>
							<Col>
								<div
									dangerouslySetInnerHTML={{
										__html: contentBlocks.below_my_session,
									}}
								></div>
							</Col>
						</Row>
					)}
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		contentBlocks: getContentBlock(state),
		isLoggedIn: (slug) => isLoggedIn(state, slug),
	};
};
const dispatchProps = {
	eventInformation,
};

export default connect(mapStateToProps, dispatchProps)(withRouter(Information));
