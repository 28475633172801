import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { connect } from 'react-redux';
import { allSessions, forgotLink, allEvents } from 'actions/event';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import { ValidateEmail } from 'util/Validator';
import { getContentBlock } from 'selectors/event';
import { isLoggedIn } from 'selectors/auth';
import CMSRegistrationForm from 'views/Registration/CMSRegistrationForm';
import { setJustLoggedIn } from 'actions/system';
import { getJustLogIn } from 'selectors/system';
import OldSponsors from 'views/Pages/OldSponsors';

const Home = (props) => {
	const [state, setState] = useState({
		events: [],
		error: '',
		displayForm: false,
		submitting: false,
		email: '',
		success: '',
	});
	const [error, setError] = useState({ email: '' });
	const [currentData, setCurrentData] = useState({
		register: '',
		slug: 'au_awards',
	});
	const {
		allSessions,
		allEvents,
		forgotLink,
		contentBlocks,
		isLoggedIn,
		justLoggedIn,
		setJustLoggedIn,
	} = props;

	function callbackSuccess(data) {
		let sortedData = data.sort(sortEvents);

		if (justLoggedIn) {
			props.history.push(`/au_awards/info`);
			setJustLoggedIn(false);
		}
		if (sortedData[0]) {
			setCurrentData({
				...currentData,
				slug: sortedData[0].slug,
				register: sortedData[0].registration,
			});
		}
		setState({ ...state, events: sortedData });
	}

	function callbackError(error) {
		setState({ ...state, error: error.message });
	}
	useEffect(() => {
		allEvents(callbackSuccess, callbackError);
		// allSessions(callbackSuccess, callbackError);
	}, [justLoggedIn]);

	const sortEvents = (a, b) => {
		const bandA = a.id;
		const bandB = b.id;

		let comparison = 0;
		if (bandA < bandB) {
			comparison = -1;
		} else if (bandA > bandB) {
			comparison = 1;
		}
		return comparison;
	};

	const forgotSuccess = () => {
		setState({
			...state,
			success: 'Session link has been sent to your email.',
			submitting: false,
			email: '',
		});
		setError({ ...error, email: '' });
	};

	const forgotError = (err) => {
		setState({ ...state, submitting: false });
		setError({
			...error,
			email: err.message.email ? err.message.email : err.message,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setState({ ...state, submitting: true });
		if (!state.email) {
			setError({ ...error, email: 'Email address is required' });
			setState({ ...state, submitting: false });
			return;
		}

		if (!ValidateEmail(state.email)) {
			setState({ ...state, submitting: false });
			setError({ ...error, email: 'Please enter valid email address.' });
			return;
		}

		forgotLink(state.email, forgotSuccess, forgotError);
	};

	return (
		<div className="welcome-info mt-3">
			<Row>
				<Col md={isLoggedIn(currentData.slug) ? 12 : 7}>
					<div
						dangerouslySetInnerHTML={{
							__html: contentBlocks.welcome_text,
						}}
					></div>
				</Col>
				<Col md={isLoggedIn(currentData.slug) ? 0 : 5}>
					{!isLoggedIn(currentData.slug) &&
						currentData.register &&
						currentData.slug && (
							<CMSRegistrationForm
								event_slug={currentData.slug}
								slug={currentData.register}
							/>
						)}
				</Col>
			</Row>
			{state.displayForm && (
				<Card className="my-2 slow-motion">
					<Card.Body>
						<Form onSubmit={(e) => handleSubmit(e)}>
							<Form.Group>
								<Form.Label>
									Please enter the email address you used to register
								</Form.Label>
								<Form.Control
									type="email"
									isInvalid={error.email}
									value={state.email}
									placeholder="Email address"
									onChange={(e) =>
										setState({ ...state, email: e.target.value })
									}
									required={true}
								/>
								<Form.Control.Feedback type="invalid">
									{error.email}
								</Form.Control.Feedback>
								{state.success && (
									<p className="small success">{state.success}</p>
								)}
							</Form.Group>
							<Button type="submit" size="sm" variant="dark mx-auto">
								{state.submitting ? (
									<>
										<Spinner animation="border" size="sm" /> Submitting
									</>
								) : (
									'Resend Link'
								)}
							</Button>
						</Form>
					</Card.Body>
				</Card>
			)}
			<OldSponsors />
			{/* <Row>
				<Col md={12}>
					<Suspense fallback={null}>
						<AllEvents videoSessions={state.events} />
					</Suspense>
					{state.error && (
						<h4 className="invalid-feedback error">{state.error}</h4>
					)}
				</Col>
			</Row> */}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		contentBlocks: getContentBlock(state),
		isLoggedIn: (slug) => isLoggedIn(state, slug),
		justLoggedIn: getJustLogIn(state),
	};
};
const dispatchProps = {
	allEvents,
	allSessions,
	forgotLink,
	setJustLoggedIn,
};

export default connect(mapStateToProps, dispatchProps)(Home);
