import React from 'react';
import { connect } from 'react-redux';
import { getContentBlock } from 'selectors/event';
import { withRouter } from 'react-router-dom';

const Agenda = (props) => {
	const { agenda_header } = props.contentBlocks;
	return (
		<div className="agenda">
			<div>{'Session timings are listed in AEST'}</div>
			<div
				dangerouslySetInnerHTML={{
					__html: agenda_header,
				}}
			></div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		contentBlocks: getContentBlock(state),
	};
};
const dispatchProps = {};

export default connect(mapStateToProps, dispatchProps)(withRouter(Agenda));
