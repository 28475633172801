export const Platinum = [
	{
		name: 'Asteron Life',
		photo: 'Asteron.jpg',
		link: 'https://www.asteronlife.co.nz/',
		info: 'Sponsor of ANZIIF Lifetime Achievement Award',
		description:
			'As a leading intermediated general and specialist insurer we’re here to make insurance mean more for our customers. That means we do what really matters to make insurance more useful and more relevant to help New Zealanders feel more confident. We distribute our products through a large network of insurance brokers and advisers, as well as through our partnerships with some of New Zealand’s leading financial institutions and retailers. We create value for our customers and business partners by making it easier to do business.',
	},
	{
		name: 'DLA PIPER',
		photo: 'DLA.jpg',
		link: 'https://www.dlapiper.com/en/newzealand ',
		info: 'Sponsor of Insurance Leader of the Year',
		description:
			"DLA Piper was the first global, business law firm operating in New Zealand. We have lawyers in the Americas, Asia Pacific, Africa, Europe and the Middle East. Across every major sector and practice, we provide innovative, commercially astute advice to organisations and businesses already operating in, or considering entry into, the New Zealand market.<br/>At DLA Piper, we believe great businesses can make a better world. That's why, every day, we help our clients succeed. Bold thinking and trusting relationships are at the heart of our work, helping clients embrace change and seize opportunities. Together, we can make business better.",
	},
	{
		name: 'JB HI-FI SOLUTIONS',
		photo: 'Solutions.jpg',
		link: 'https://solutions.jbhifi.com.au/solutions/',
		info: 'Sponsor of General Insurance Company of the Year',
		description:
			'JB Hi-Fi Solutions for Insurance has been Australia and New Zealand’s leading insurance replacement service since 2005. Solutions for Insurance have supported the insurance industry by providing end-to-end replacement and fulfilment for home, warranty and travel insurance companies. Operating from a centralised call centre in Melbourne, with a team of 24 experienced and highly skilled Insurance Advisors, Solutions for Insurance offers a simple, one-stop electronic replacement process for claimants throughout Australasia. <br/>Our Insurance Advisors are trained to validate claims, replace and arrange the supply of products. Working in conjunction with the JB Hi-Fi retail store network, Solutions for Insurance accelerates the product replacement process by allowing claimants to collect their lost or damaged products the same day as claim lodgement. Solutions for Insurance is unique in the industry as it offers a broad range of products, decreases claim touch points, accelerates the product claim process, facilitates timely replacements, and seamless operations and service to clients through our product validation, a dedicated insurance system and gift card replacements.',
	},
	{
		name: 'MANHEIM',
		photo: 'Manheim.jpg',
		link: 'https://www.manheim.com.au/',
		info: 'Sponsor of Innovation of the Year',
		description:
			'Manheim is one of the largest sellers of written off assets for insurers across the Asia Pacific region selling more than 150,000 salvage motor vehicles, motorcycles and damaged Truck, Plant and Machinery.<br/><br/>As part of Cox Automotive Australia and operating in Australia for more than 60 years Manheim is one of the leading providers of innovative online solutions for asset management and remarketing in the automotive and truck and machinery salvage sectors with products including ExactBids, TMS and MARS.<br/><br/>In addition to providing auction sales for insurance salvage assets Manheim provides logistics support through a network of national transport providers across Australia and New Zealand, manages a regional partnership storage network and provides reporting and analytics services.<br/><br/>As part of Cox Automotive Australia, Manheim’s partner brands include MVR, Kelley Blue Book, Dealer Solutions, Xtime and DealerCell.',
	},
	{
		name: 'NZI',
		photo: 'NZI.png',
		info: 'Sponsor of Young Insurance Professional of the Year',
		link: 'https://www.nzi.co.nz/en.html',
		description:
			'As one of New Zealand’s longest established insurers, NZI is proud to have helped countless Kiwis weather losses over the last 160 years. We pride ourselves on keeping pace with industry changes, and staying ahead of the curve when it comes to opportunities and risks faced by Kiwi businesses.<br/><br/>We’re experienced insurers and experts in risk management. When you’re with NZI, you get more than insurance. We actively help Kiwi businesses manage their risks and provide support through thick and thin.<br/><br/>By working with local brokers around New Zealand, we can tailor insurance to meet your needs.',
	},
	{
		name: 'Vero',
		photo: 'Vero.jpg',
		info: 'Sponsor of Broking Professional of the Year',
		link: 'https://www.vero.co.nz/ ',
		description:
			'We’re here to support you. When we say “Supporters for life”, we mean it. This is who we are as a company, this is our purpose because our customers and advisers are at the heart of everything we do. Insuring Kiwis for is what we’ve been doing since 1878 and we go out of our way to make our products more flexible and our service more genuine.',
	},
];

export const Gold = [
	{
		name: 'Sedgwick',
		photo: 'Sedgwick.jpg',
		link: 'https://www.sedgwick.com/solutions/global/nz',
		description:
			'Sedgwick brings together local expertise and unrivalled global capabilities to provide leading claims management and integrated business solutions across New Zealand and around the world. <br/>Sedgwick New Zealand has 22 dedicated branches throughout New Zealand, providing the services and solutions you are looking for, where and when you need us most. Backed by our digital capabilities, we offer a cost-effective and innovative approach that allows you to centralise and simplify your claims management. In addition, we are leaders in specialist services including Major and Complex Loss, forensic accounting, Third Party Administration, Building Consultancy and Engineering. <br/>Our guiding corporate principles support all those we work with through our caring counts® philosophy. Taking care of people and organisations — from public and private employers, to insurers and their customers — is at the heart of everything we do. We are proud to support the New Zealand insurance industry by sponsoring these awards.',
	},
];

export const Silver = [
	{
		name: 'DUCK CREEK TECHNOLOGIES',
		photo: 'Duck Creek.jpg',
		link: 'https://www.duckcreek.com/',
		description:
			'Duck Creek Technologies (NASDAQ: DCT) is a leading provider of core system solutions to the P&C and General insurance industry. By accessing Duck Creek OnDemand, the company’s enterprise Software-as-a-Service solution, insurers are able to navigate uncertainty and capture market opportunities faster than their competitors. Duck Creek’s functionally-rich solutions are available on a standalone basis or as a full suite, and all are available via Duck Creek OnDemand. For more information, visit <a href="http://www.duckcreek.com" target="_blank">www.duckcreek.com.</a>',
	},
	{
		name: 'INVEST HK',
		photo: 'InvestHK.jpg',
		link: 'https://www.investhk.gov.hk/en/home.html',
		description:
			"Invest Hong Kong is the department of the Hong Kong SAR Government responsible for attracting Foreign Direct Investment, supporting overseas and Mainland businesses to set up and expand in Hong Kong.<br/><br/>The department provides free advice and services to help businesses succeed in Hong Kong's economy.<br/><br/>Headquartered in Hong Kong, Invest Hong Kong has an overseas office in Sydney for all enquiries from Australia and New Zealand companies. Contact Dr Luca De Leonardis, Head Investment Promotion - Australia & New Zealand on +61 2 9286 2358 for more information.",
	},
	{
		name: 'MVR',
		photo: 'Motor Vehicle Replace.jpg',
		link: 'https://www.motorvehiclereplacement.com.au/',
		description:
			'Motor Vehicle Replacement (MVR) is one of the insurance industry’s fastest growing vehicle replacement providers. Utilising an innovative reverse auction platform, MVR provides a unique level of transparency in the motor vehicle replacement sector.<br/><br/>Utilising its network of more than 2,500 new vehicle franchise dealers across Australia, MVR allows dealers to compete in an open and transparent environment across a defined time period to provide the lowest replacement vehicle costs for insurers.<br/><br/>MVR works hand in hand with partner brand Manheim to provide additional connectivity in the motor vehicle total loss process. As part of Cox Automotive Australia, MVR continues to expand and develop as it works to integrate with leading claims management systems.',
	},
	{
		name: 'SKL EXECUTIVE',
		photo: 'Sklactuarial.jpg',
		link: 'https://www.sklactuarial.com.au/',
		description:
			'SKL is a specialist actuarial recruitment firm which works across the financial services industry. SKL brings a deep knowledge of the market, including technical expertise as two of our founders are Fellows of the Actuaries Institute, and have very strong networks in the profession.<br/><br/>SKL has a diverse team of consultants who recruit at all levels ranging from actuarial analysts to undertaking executive search assignments for very senior roles. SKL adopt a consultative approach, provide realistic advice about the market and industry and are committed to working in partnership with their clients.<br/>The choice to work with actuaries is due to our strong belief in their skills and knowledge base. Individuals who can combine these skills and knowledge with the strength of a professional qualification, offer a unique and highly valuable resource to organisations who seek high quality, talented staff.',
	},
];

export const Judges = [
	{
		name: 'Damian Falkingham,<br/>ANZIIF',
		photo: 'Damian Falkingham 02_Grayscale.jpg',
	},
	{
		name: 'Tim Grafton,<br/>ICNZ',
		photo: 'Tim Grafton.jpg',
	},
	{
		name: 'Ben Holloway,<br/>Partners Life',
		photo: 'Ben Holloway.jpg',
	},
	{
		name: 'Sara Knox,<br/>ICNZ',
		photo: 'Sarah Knox.png',
	},
	{
		name: 'Caroline Laband,<br/>DLA Piper',
		photo: 'Caroline Laband_Grayscale.jpg',
	},
	{
		name: 'Sara Malins,<br/>Sedgwick',
		photo: 'Sara Malins.jpg',
	},
	{
		name: 'Glen Phillpotts,<br/>ANZIIF',
		photo: 'Phillipot_Glen.jpg',
	},
	{
		name: 'Rebecca Slingo,<br/>ANZIIF',
		photo: 'Rebecca Slingo 02_Grayscale 113x113.jpg',
	},
	{
		name: 'Karen Stevens,<br/>Insurance and Financial Services Ombudsman',
		photo: 'Karen Stevens NEW.jpg',
	},
];

export const AgendaData = [
	{
		name: 'NAB Federal Budget 2021 Insights for Metro Australia Customers',
		sessions: [
			{
				time: '9:00am',
				session: ['Nassim Khadem (MC) Welcome and Session Introduction'],
			},
			{
				time: '9:05am',
				session: ['Federal Budget 2021 economic analysis with Alan Oster'],
			},
			{
				time: '9:25am',
				session: [
					'Panel discussion with Michael Saadie, Sally Auld, Paul Abbey and Alan Oster, hosted Nassim Khadem',
				],
			},
			{
				time: '9:45am',
				session: ['Live Q&A with audience'],
			},
			{
				time: '10:00am',
				session: ['Event to conclude'],
			},
		],
	},
	{
		name: 'NAB Federal Budget 2021 Insights for Regional Australia and Agribusiness Customers',
		sessions: [
			{
				time: '11:00am',
				session: ['Nassim Khadem (MC) Welcome and Session Introduction'],
			},
			{
				time: '11:05am',
				session: ['Federal Budget 2021 economic analysis with Phin Ziebell'],
			},
			{
				time: '11:25am',
				session: [
					'Panel discussion with Julie Rynski, Sally Auld, Paul Abbey and Phin Ziebell, hosted by Nassim Khadem',
				],
			},
			{
				time: '11:45am',
				session: ['Live Q&A to commence'],
			},
			{
				time: '12:00pm',
				session: ['Event to Conclude'],
			},
		],
	},
];

export const FirstSixSession = [
	{
		timing: '11:05am – 11:10am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 1:</span>  Welcome and day 1 introduction',
		sessionInformation:
			'Day one introduction and overview – what to expect and how to get the most out of the webinar series.<br/><br/><div class="theme-title">Hosts:</div><br/><ul><li>Adam Brown, Executive - Advantedge</li></ul>',
	},
	{
		timing: '11:10am – 11:15am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 2:</span>  Adapting and thriving in changing times',
		sessionInformation:
			'Listen to the experiences of a range of brokers and BDMs during COVID-19 and how Advantedge has supported them in the process. Prepare to be inspired!  ',
	},
	{
		timing: '11:15am – 11:25pm [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 3:</span>  Built for simplicity, built for better',
		sessionInformation:
			'Take an exclusive first look at the new Advantedge customer and broker value propositions, all based on extensive quant and qual research.<br/><br/><div class="theme-title">Host:</div><br/><ul><li>Vanessa Fekonja, Senior Marketing Consultant</li></ul>',
	},
	{
		timing: '11:25am – 11:45am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 4:</span>  Deep dive – learnings and new initiatives',
		sessionInformation:
			'Join the Advantedge Leadership Team as they discuss the learnings of the past year and take you through a series of key initiatives focused on simplification, fulfilment and servicing.<br/><br/><div class="theme-title">Hosts:</div><br/><ul><li>Adam Brown, Executive - Advantedge</li><li>Geoff Bungey, Head of Customer Epidsode, Advantedge</li><li>Peter Fourtzis, Distribution Support Manager, Advantedge</li><li>Sarah- Jane Hawkes, Production Manager, Advantedge Servicing and Fulfilment</li></ul>',
	},
	{
		timing: '11:45pm – 11:50am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 5:</span>  You Can’t Ask That...or can you?',
		sessionInformation:
			'Watch the Advantedge team get put through their paces in a <i>You Can’t Ask That</i>-style Q&A. This session is set to offer a mix of thought-provoking and entertaining insights.<br/><br/><div class="theme-title">Hosts:</div><br/><ul><li>Christian Malouf, Manager, Business Development, Advantedge</li><li>Daniel Barrow, Head of Broker Sales – Northern Region, Advantedge</li><li>Brad Crawford, Business Development Manager, Advantedge</li></ul>',
	},
	{
		timing: '11:50am – 11:55am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 6:</span>  Day 1 wrap and close',
		sessionInformation: 'Day one wrap and close – quick overview of day two.',
	},
];

export const SecondFourSessions = [
	{
		timing: '11:05am – 11:10am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 7:</span>  Welcome and day 2 introduction',
		sessionInformation:
			'Day two introduction and overview. Brief re-cap and feedback from day one.<br/><br/><div class="theme-title">Hosts:</div><br/><ul><li>Adam Brown, Executive - Advantedge</li></ul>',
	},
	{
		timing: '11:10am – 11:25am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 8:</span>  CoreLogic: market trends and outlook',
		sessionInformation:
			'With coverage of 98% of the Australian property market and a database of more than 4 billion decision points, CoreLogic offers the most reliable and powerful insights. Tune in to hear the latest trends and broker-first opportunities.<br/><br/><div class="theme-title">Hosts:</div><br/><ul><li>Mark Browning, Head of Property Services Group, NAB</li><li>Tim Lawless, Head of Research, CoreLogic Asia Pacific</li></ul>',
	},
	{
		timing: '11:25am – 11:50am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 9:</span>  Live Q&A with the Advantedge expert panel',
		sessionInformation:
			'Join our live expert panel to get all the answers to your burning questions of the day, and everything else in between! We encourage you to send your questions ahead of the webinar, or feel free to submit them on the day.<br/><br/><div class="theme-title">Hosts:</div><br/><ul><li>Jess Vaiano, Head of Broker Sales – Southern Region, Advantedge</li><li>Sarah-Jane Hawkes, Production Manager, Advantedge Servicing and Fulfilment</li><li>Steven Marinucci, Manager, Business Development Manager, Advantedge</li></ul>',
	},
	{
		timing: '11:50am – 11:55am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 10:</span>  Day 2 wrap, close and thank you',
		sessionInformation:
			'Event wrap and close, thank you and our commitment to you.',
	},
];
