export const Platinum = [
	{
		name: 'JB HI-FI SOLUTIONS',
		photo: 'Solutions.jpg',
		link: 'https://solutions.jbhifi.com.au/solutions/',
		info: 'Sponsor of Small General Insurance Company of the Year',
		description:
			'JB Hi-Fi Solutions for Insurance has been Australia and New Zealand’s leading insurance replacement service since 2005. Solutions for Insurance have supported the insurance industry by providing end-to-end replacement and fulfilment for home, warranty and travel insurance companies. Operating from a centralised call centre in Melbourne, with a team of 24 experienced and highly skilled Insurance Advisors, Solutions for Insurance offers a simple, one-stop electronic replacement process for claimants throughout Australasia.<br/><br/>Our Insurance Advisors are trained to validate claims, replace and arrange the supply of products. Working in conjunction with the JB Hi-Fi retail store network, Solutions for Insurance accelerates the product replacement process by allowing claimants to collect their lost or damaged products the same day as claim lodgement.<br/><br/>Solutions for Insurance is unique in the industry as it offers a broad range of products, decreases claim touch points, accelerates the product claim process, facilitates timely replacements, and seamless operations and service to clients through our product validation, a dedicated insurance system and gift card replacements.',
	},
	{
		name: 'QBE',
		photo: 'QBE.jpg',
		link: 'https://www.qbe.com/au',
		info: 'Sponsor of Large Broking Company of the Year',
		description:
			'QBE Australia is part of the QBE Insurance Group, an international insurer and reinsurer headquartered in Sydney with offices across all states and territories. Our purpose is to give people the confidence to achieve their ambitions.<br/><br/>We provide a broad range of insurance products to personal, business, corporate and institutional customers. From car and home insurance, to tailored business packages and specialist cover for industries such as aviation and farming.<br/><br/>We don’t simply offer insurance. We’re all about the before and after – helping our customers to protect and prevent against the ‘what ifs’ and being there to support if anything goes wrong.',
	},
	{
		name: 'SEDGWICK',
		photo: 'Sedgwick.jpg',
		link: 'https://www.sedgwick.com/solutions/global/au',
		info: 'Sponsor of Insurtech of the Year',
		description:
			'Sedgwick is the largest provider of technology enabled claims management services in the world, including loss adjusting, forensic accounting, building consultancy and third-party administration solutions.<br/><br/>Locally, as a partner of Insurtech Australia, Sedgwick is recognised for its commitment to innovation in addition to the ability to manage all types of insurance claims and provide expert advice on building repairs, fraud matters and financial valuations.',
	},
	{
		name: 'STEADFAST',
		photo: 'Steadfast.jpg',
		link: 'https://www.steadfast.com.au/',
		info: 'Sponsor of Large General Insurance Company of the Year',
		description:
			'Steadfast Group was established in 1996 and is the largest general insurance broker network and the largest underwriting agency group in Australasia, with growing operations in Asia and Europe.<br/><br/>We have grown the Steadfast Network to 458 general insurance brokerages, built a portfolio of 25 underwriting agencies and we have a 40% interest in the unison Steadfast network of 236 brokerages globally. We are a key distribution channel for our insurer partners as the Steadfast Network has a large and diverse client base across Australia.',
	},
	{
		name: 'UAC',
		photo: 'UAC.jpg',
		link: 'https://uac.org.au/ ',
		info: 'Sponsor of Underwriting Agency of the Year',
		description:
			'The Underwriting Agencies Council (UAC) is the peak body for Australian underwriting agencies, who write annual GWP of more than $6.5bn.<br/><br/>UAC serves members’ interests through professional development events; broker expos to showcase members’ specialist products; sector-specific marketing campaigns; and advocacy with government and industry bodies. UAC has more than 100 agency members, supported by 57 business service members.',
	},
];

export const Gold = [
	{
		name: 'ALLIANZ',
		photo: 'Allianz.jpg',
		link: 'https://www.allianz.com.au/',
		description:
			'As one of Australia’s largest general insurers, more than 3.5 million Australians trust Allianz with their insurance needs. Allianz Australia also insures over 25% of the top 200 ASX companies, making us one of the leading private workers compensation insurers in the country.<br/><br/>For 11 years, Allianz Australia has also been recognised as an Employer of Choice for Gender Equality by the Workplace Gender Equality Agency. We are committed to helping to secure the futures of our customers and employees, and we use our global and local experience to tackle some of the most significant societal issues, including climate change and mental health.',
	},
	{
		name: 'MANHEIM',
		photo: 'Manheim.jpg',
		link: 'https://www.manheim.com.au/',
		description:
			'Manheim is one of the largest sellers of written off assets for insurers across the Asia Pacific region selling more than 150,000 salvage motor vehicles, motorcycles and damaged Truck, Plant and Machinery.<br/><br/>As part of Cox Automotive Australia and operating in Australia for more than 60 years Manheim is one of the leading providers of innovative online solutions for asset management and remarketing in the automotive and truck and machinery salvage sectors with products including ExactBids, TMS and MARS.<br/><br/>In addition to providing auction sales for insurance salvage assets Manheim provides logistics support through a network of national transport providers across Australia and New Zealand, manages a regional partnership storage network and provides reporting and analytics services.<br/><br/>As part of Cox Automotive Australia, Manheim’s partner brands include MVR, Kelley Blue Book, Dealer Solutions, Xtime and DealerCell.',
	},
	{
		name: 'STELVIO',
		photo: 'Stelvio.jpg',
		link: 'https://www.stelvio.com/',
		description: '',
	},
];

export const Silver = [
	{
		name: 'DUCK CREEK TECHNOLOGIES',
		photo: 'Duck Creek.jpg',
		link: 'https://www.duckcreek.com/',
		description:
			'Duck Creek Technologies (NASDAQ: DCT) is a leading provider of core system solutions to the P&C and General insurance industry. By accessing Duck Creek OnDemand, the company’s enterprise Software-as-a-Service solution, insurers are able to navigate uncertainty and capture market opportunities faster than their competitors. Duck Creek’s functionally-rich solutions are available on a standalone basis or as a full suite, and all are available via Duck Creek OnDemand. For more information, visit <a href="http://www.duckcreek.com" target="_blank">www.duckcreek.com.</a>',
	},
	{
		name: 'INVEST HK',
		photo: 'InvestHK.jpg',
		link: 'https://www.investhk.gov.hk/en/home.html',
		description:
			"Invest Hong Kong is the department of the Hong Kong SAR Government responsible for attracting Foreign Direct Investment, supporting overseas and Mainland businesses to set up and expand in Hong Kong.<br/><br/>The department provides free advice and services to help businesses succeed in Hong Kong's economy.<br/><br/>Headquartered in Hong Kong, Invest Hong Kong has an overseas office in Sydney for all enquiries from Australia and New Zealand companies. Contact Dr Luca De Leonardis, Head Investment Promotion - Australia & New Zealand on +61 2 9286 2358 for more information.",
	},
	{
		name: 'MVR',
		photo: 'Motor Vehicle Replace.jpg',
		link: 'https://www.motorvehiclereplacement.com.au/',
		description:
			'Motor Vehicle Replacement (MVR) is one of the insurance industry’s fastest growing vehicle replacement providers. Utilising an innovative reverse auction platform, MVR provides a unique level of transparency in the motor vehicle replacement sector.<br/><br/>Utilising its network of more than 2,500 new vehicle franchise dealers across Australia, MVR allows dealers to compete in an open and transparent environment across a defined time period to provide the lowest replacement vehicle costs for insurers.<br/><br/>MVR works hand in hand with partner brand Manheim to provide additional connectivity in the motor vehicle total loss process. As part of Cox Automotive Australia, MVR continues to expand and develop as it works to integrate with leading claims management systems.',
	},
	{
		name: 'SKL EXECUTIVE',
		photo: 'Sklactuarial.jpg',
		link: 'https://www.sklactuarial.com.au/',
		description:
			'SKL is a specialist actuarial recruitment firm which works across the financial services industry. SKL brings a deep knowledge of the market, including technical expertise as two of our founders are Fellows of the Actuaries Institute, and have very strong networks in the profession.<br/><br/>SKL has a diverse team of consultants who recruit at all levels ranging from actuarial analysts to undertaking executive search assignments for very senior roles. SKL adopt a consultative approach, provide realistic advice about the market and industry and are committed to working in partnership with their clients.<br/>The choice to work with actuaries is due to our strong belief in their skills and knowledge base. Individuals who can combine these skills and knowledge with the strength of a professional qualification, offer a unique and highly valuable resource to organisations who seek high quality, talented staff.',
	},
];

export const Judges = [
	{
		name: 'Jenny Bax',
		photo: 'Jenny Bax.JPG',
	},
	{
		name: 'James Burnet,<br/> ANZIIF',
		photo: 'James Burnet.jpg',
	},
	{
		name: 'Fiona Cameron,<br/> ICA',
		photo: 'Fiona Cameron.jpg',
	},
	{
		name: 'Luke Davies,<br/> ANZIIF',
		photo: 'Luke Davies.jpg',
	},
	{
		name: 'Linda Ellis,<br/> Steadfast',
		photo: 'Linda Ellis.jpg',
	},
	{
		name: 'Damian Falkingham,<br/> ANZIIF',
		photo: 'Damian Falkingham.jpg',
	},
	{
		name: 'Kimberley Jonsson,<br/> CHU Underwriting Agencies',
		photo: 'Kimberley Jonsson.jpg',
	},
	{
		name: 'William Legge,<br/> Underwriting Agencies Council',
		photo: 'William Legge.jpg',
	},
	{
		name: 'Gabriele McDonald,<br/> Protecsure',
		photo: 'Gabriele McDonald.png',
	},
	{
		name: 'Jillian Pancott,<br/> Sedgwick',
		photo: 'Jillian Pancott.jpg',
	},
	{
		name: 'Natasha Quirk,<br/> Hall Advisory',
		photo: 'Natasha Quirk.jpg',
	},
	{
		name: 'Rebecca Slingo,<br/> ANZIIF',
		photo: 'Rebecca Slingo.jpg',
	},
	{
		name: 'Rob Whelan',
		photo: 'Rob Whelan.jpg',
	},
	{
		name: 'Prue Willsford,<br/> ANZIIF',
		photo: 'Prue Willsford.jpg',
	},
	{
		name: 'Rita Yates,<br/> Insurtech Australia',
		photo: 'Rita Yates.jpg',
	},
	{
		name: 'Angie Zissis,<br/> Sura',
		photo: 'Angie Zissis.jpg',
	},
];

export const AgendaData = [
	{
		name: 'NAB Federal Budget 2021 Insights for Metro Australia Customers',
		sessions: [
			{
				time: '9:00am',
				session: ['Nassim Khadem (MC) Welcome and Session Introduction'],
			},
			{
				time: '9:05am',
				session: ['Federal Budget 2021 economic analysis with Alan Oster'],
			},
			{
				time: '9:25am',
				session: [
					'Panel discussion with Michael Saadie, Sally Auld, Paul Abbey and Alan Oster, hosted Nassim Khadem',
				],
			},
			{
				time: '9:45am',
				session: ['Live Q&A with audience'],
			},
			{
				time: '10:00am',
				session: ['Event to conclude'],
			},
		],
	},
	{
		name: 'NAB Federal Budget 2021 Insights for Regional Australia and Agribusiness Customers',
		sessions: [
			{
				time: '11:00am',
				session: ['Nassim Khadem (MC) Welcome and Session Introduction'],
			},
			{
				time: '11:05am',
				session: ['Federal Budget 2021 economic analysis with Phin Ziebell'],
			},
			{
				time: '11:25am',
				session: [
					'Panel discussion with Julie Rynski, Sally Auld, Paul Abbey and Phin Ziebell, hosted by Nassim Khadem',
				],
			},
			{
				time: '11:45am',
				session: ['Live Q&A to commence'],
			},
			{
				time: '12:00pm',
				session: ['Event to Conclude'],
			},
		],
	},
];

export const FirstSixSession = [
	{
		timing: '11:05am – 11:10am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 1:</span>  Welcome and day 1 introduction',
		sessionInformation:
			'Day one introduction and overview – what to expect and how to get the most out of the webinar series.<br/><br/><div class="theme-title">Hosts:</div><br/><ul><li>Adam Brown, Executive - Advantedge</li></ul>',
	},
	{
		timing: '11:10am – 11:15am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 2:</span>  Adapting and thriving in changing times',
		sessionInformation:
			'Listen to the experiences of a range of brokers and BDMs during COVID-19 and how Advantedge has supported them in the process. Prepare to be inspired!  ',
	},
	{
		timing: '11:15am – 11:25pm [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 3:</span>  Built for simplicity, built for better',
		sessionInformation:
			'Take an exclusive first look at the new Advantedge customer and broker value propositions, all based on extensive quant and qual research.<br/><br/><div class="theme-title">Host:</div><br/><ul><li>Vanessa Fekonja, Senior Marketing Consultant</li></ul>',
	},
	{
		timing: '11:25am – 11:45am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 4:</span>  Deep dive – learnings and new initiatives',
		sessionInformation:
			'Join the Advantedge Leadership Team as they discuss the learnings of the past year and take you through a series of key initiatives focused on simplification, fulfilment and servicing.<br/><br/><div class="theme-title">Hosts:</div><br/><ul><li>Adam Brown, Executive - Advantedge</li><li>Geoff Bungey, Head of Customer Epidsode, Advantedge</li><li>Peter Fourtzis, Distribution Support Manager, Advantedge</li><li>Sarah- Jane Hawkes, Production Manager, Advantedge Servicing and Fulfilment</li></ul>',
	},
	{
		timing: '11:45pm – 11:50am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 5:</span>  You Can’t Ask That...or can you?',
		sessionInformation:
			'Watch the Advantedge team get put through their paces in a <i>You Can’t Ask That</i>-style Q&A. This session is set to offer a mix of thought-provoking and entertaining insights.<br/><br/><div class="theme-title">Hosts:</div><br/><ul><li>Christian Malouf, Manager, Business Development, Advantedge</li><li>Daniel Barrow, Head of Broker Sales – Northern Region, Advantedge</li><li>Brad Crawford, Business Development Manager, Advantedge</li></ul>',
	},
	{
		timing: '11:50am – 11:55am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 6:</span>  Day 1 wrap and close',
		sessionInformation: 'Day one wrap and close – quick overview of day two.',
	},
];

export const SecondFourSessions = [
	{
		timing: '11:05am – 11:10am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 7:</span>  Welcome and day 2 introduction',
		sessionInformation:
			'Day two introduction and overview. Brief re-cap and feedback from day one.<br/><br/><div class="theme-title">Hosts:</div><br/><ul><li>Adam Brown, Executive - Advantedge</li></ul>',
	},
	{
		timing: '11:10am – 11:25am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 8:</span>  CoreLogic: market trends and outlook',
		sessionInformation:
			'With coverage of 98% of the Australian property market and a database of more than 4 billion decision points, CoreLogic offers the most reliable and powerful insights. Tune in to hear the latest trends and broker-first opportunities.<br/><br/><div class="theme-title">Hosts:</div><br/><ul><li>Mark Browning, Head of Property Services Group, NAB</li><li>Tim Lawless, Head of Research, CoreLogic Asia Pacific</li></ul>',
	},
	{
		timing: '11:25am – 11:50am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 9:</span>  Live Q&A with the Advantedge expert panel',
		sessionInformation:
			'Join our live expert panel to get all the answers to your burning questions of the day, and everything else in between! We encourage you to send your questions ahead of the webinar, or feel free to submit them on the day.<br/><br/><div class="theme-title">Hosts:</div><br/><ul><li>Jess Vaiano, Head of Broker Sales – Southern Region, Advantedge</li><li>Sarah-Jane Hawkes, Production Manager, Advantedge Servicing and Fulfilment</li><li>Steven Marinucci, Manager, Business Development Manager, Advantedge</li></ul>',
	},
	{
		timing: '11:50am – 11:55am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 10:</span>  Day 2 wrap, close and thank you',
		sessionInformation:
			'Event wrap and close, thank you and our commitment to you.',
	},
];
