import React from 'react';
import { getImageUrl } from 'helper/helper';
import { Platinum, Gold, Silver } from 'assets/data/static';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Speaker = () => {
	return (
		<div className="mt-5">
			<div className="container-for">PLATINUM</div>
			<div className="speakers mt-4">
				{Platinum.length > 0 &&
					Platinum.map((data, index) => (
						<div className="speaker" key={index}>
							<>
								<div className="speaker-details">
									<Row>
										<Col md={3} sm={12} lg={2}>
											<div className="speakers-container">
												<a
													href={data.link}
													target="_blank"
													rel="noreferrer"
													title={data.name}
												>
													<img
														onError={(e) => (e.target.style.display = 'none')}
														src={getImageUrl(
															`/assets/images/2021_sponsors/${data.photo}`
														)}
													/>
												</a>
												<div className="sponsor-info">{data.info}</div>
											</div>
										</Col>
										<Col md={9} sm={12} lg={10} className="pl-4">
											<div className="speaker-info">{data.name}</div>
											<div
												dangerouslySetInnerHTML={{
													__html: data.description,
												}}
											></div>
										</Col>
									</Row>
								</div>
							</>
							<div className="speaker-divider"></div>
						</div>
					))}
			</div>
			<div className="container-for">GOLD</div>
			<div className="speakers mt-4">
				{Gold.length > 0 &&
					Gold.map((data, index) => (
						<div className="speaker" key={index}>
							<>
								<div className="speaker-details">
									<Row>
										<Col md={3} sm={12} lg={2}>
											<div className="speakers-container">
												<a
													href={data.link}
													target="_blank"
													rel="noreferrer"
													title={data.name}
												>
													<img
														onError={(e) => (e.target.style.display = 'none')}
														src={getImageUrl(
															`/assets/images/2021_sponsors/${data.photo}`
														)}
													/>
												</a>
												<div className="sponsor-info">{data.info}</div>
											</div>
										</Col>
										<Col md={9} sm={12} lg={10} className="pl-4">
											<div className="speaker-info">{data.name}</div>
											<div
												dangerouslySetInnerHTML={{
													__html: data.description,
												}}
											></div>
										</Col>
									</Row>
								</div>
							</>
							<div className="speaker-divider"></div>
						</div>
					))}
			</div>
		</div>
	);
};

export default Speaker;
