import React from 'react';
import { getImageUrl } from 'helper/helper';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const OldSponsors = () => {
	return (
		<div className="sponsors">
			<div className="container-for mt-4">Thank you to our sponsors</div>
			<Row>
				<Col md={12}>
					<div className="mt-5">PLATINUM</div>
					<div className="sponsors-container margin">
						<div className="sponsor">
							<a
								href="https://solutions.jbhifi.com.au/solutions/"
								target="_blank"
								rel="noreferrer"
								title="JB HI-FI SOLUTIONS"
							>
								<img
									src={getImageUrl('/assets/images/sponsors/Solutions.jpg')}
								/>
							</a>
						</div>
						<div className="sponsor">
							<a
								href="https://www.qbe.com/au"
								target="_blank"
								title="QBE"
								rel="noreferrer"
							>
								<img src={getImageUrl('/assets/images/sponsors/QBE.jpg')} />
							</a>
						</div>
						<div className="sponsor">
							<a
								href="https://www.sedgwick.com/solutions/global/au"
								rel="noreferrer"
								target="_blank"
								title="Sedgwick"
							>
								<img
									src={getImageUrl('/assets/images/sponsors/Sedgwick.jpg')}
								/>
							</a>
						</div>
					</div>
					<div className="sponsors-container margin">
						<div className="sponsor">
							<a
								href="https://www.steadfast.com.au/"
								target="_blank"
								title="Steadfast"
								rel="noreferrer"
							>
								<img
									src={getImageUrl('/assets/images/sponsors/Steadfast.jpg')}
								/>
							</a>
						</div>
						<div className="sponsor">
							<a
								href="https://uac.org.au/"
								target="_blank"
								title="UAC"
								rel="noreferrer"
							>
								<img src={getImageUrl('/assets/images/sponsors/UAC.jpg')} />
							</a>
						</div>
					</div>
					<div className="mt-5">GOLD</div>
					<div className="sponsors-container margin">
						<div className="sponsor">
							<a
								href="https://www.allianz.com.au/"
								target="_blank"
								title="ALLIANZ"
								rel="noreferrer"
							>
								<img src={getImageUrl('/assets/images/sponsors/Allianz.jpg')} />
							</a>
						</div>
						<div className="sponsor">
							<a
								href="https://www.manheim.com.au/"
								target="_blank"
								title="MANHEIM"
								rel="noreferrer"
							>
								<img src={getImageUrl('/assets/images/sponsors/Manheim.jpg')} />
							</a>
						</div>
						<div className="sponsor">
							<a
								href="https://www.stelvio.com/"
								target="_blank"
								title="Stelvio"
								rel="noreferrer"
							>
								<img src={getImageUrl('/assets/images/sponsors/Stelvio.jpg')} />
							</a>
						</div>
					</div>
					<div className="mt-5">SILVER</div>
					<div className="sponsors-container margin">
						<div className="sponsor">
							<a
								href="https://www.duckcreek.com/"
								target="_blank"
								title="Duck Creek Technologies"
								rel="noreferrer"
							>
								<img
									src={getImageUrl('/assets/images/sponsors/Duck Creek.jpg')}
								/>
							</a>
						</div>
						<div className="sponsor">
							<a
								href="https://www.investhk.gov.hk/en/home.html"
								target="_blank"
								title="INVEST HK"
								rel="noreferrer"
							>
								<img
									src={getImageUrl('/assets/images/sponsors/InvestHK.jpg')}
								/>
							</a>
						</div>
						<div className="sponsor">
							<a
								href="https://www.motorvehiclereplacement.com.au/"
								target="_blank"
								title="Motor Vehicle Replace"
								rel="noreferrer"
							>
								<img
									src={getImageUrl(
										'/assets/images/sponsors/Motor Vehicle Replace.jpg'
									)}
								/>
							</a>
						</div>
						<div className="sponsor">
							<a
								href="https://www.sklactuarial.com.au/"
								target="_blank"
								title="SKLACTUARIAL"
								rel="noreferrer"
							>
								<img
									src={getImageUrl('/assets/images/sponsors/Sklactuarial.jpg')}
								/>
							</a>
						</div>
					</div>
					<div className="mt-5">AUDITOR</div>
					<div className="sponsors-container margin">
						<div className="sponsor">
							<a
								href="https://www.ey.com/en_au"
								target="_blank"
								title="EY"
								rel="noreferrer"
							>
								<img src={getImageUrl('/assets/images/sponsors/EY.jpg')} />
							</a>
						</div>
					</div>
					<div className="mt-5">CO-FOUNDING PARTNER</div>
					<div className="sponsors-container margin">
						<div className="sponsor">
							<a
								href="https://www.asiainsurancereview.com/"
								target="_blank"
								title="Asia Insurance Review"
								rel="noreferrer"
							>
								<img
									src={getImageUrl(
										'/assets/images/sponsors/Asia Insurance Review.jpg'
									)}
								/>
							</a>
						</div>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default OldSponsors;
