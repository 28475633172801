import React from 'react';
import { getImageUrl } from 'helper/helper';
import { Judges } from 'assets/data/static';

const Speaker = () => {
	return (
		<div className="mt-5">
			<div className="title theme-color">JUDGES</div>
			<div className="container-for mt-4">PRESIDING JUDGE</div>
			<div className="judges-container mt-4">
				<div className="judges">
					<div className="judge judge-p">
						<div className="judge-img">
							<img
								alt="Prue Willsford, <br/>ANZIIF"
								src={getImageUrl(
									`/assets/images/judges/Prue Willsford Square.jpg`
								)}
							/>
						</div>
						<div
							dangerouslySetInnerHTML={{
								__html: `Prue Willsford,<br/>ANZIIF`,
							}}
							className="judge-name"
						></div>
					</div>
					<div className="judge judge-p">
						<div className="judge-img"></div>
						<div
							dangerouslySetInnerHTML={{
								__html: ``,
							}}
							className="judge-name"
						></div>
					</div>
					<div className="judge judge-p">
						<div className="judge-img"></div>
						<div
							dangerouslySetInnerHTML={{
								__html: ``,
							}}
							className="judge-name"
						></div>
					</div>
				</div>
			</div>
			<div className="container-for">JUDGING PANEL</div>
			<div className="judges-container mt-4">
				<div className="judges">
					{Judges.length > 0 &&
						Judges.map((data, index) => (
							<div className={`judge judge-p`} key={index}>
								<div className="judge-img">
									<img
										alt={data.name.split(',')[0]}
										src={getImageUrl(`/assets/images/judges/${data.photo}`)}
									/>
								</div>
								<div
									dangerouslySetInnerHTML={{
										__html: data.name,
									}}
									className="judge-name"
								></div>
							</div>
						))}
				</div>
			</div>
		</div>
	);
};

export default Speaker;
