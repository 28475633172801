import React from 'react';
import { getImageUrl } from 'helper/helper';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const Sponsors = () => {
	return (
		<div className="sponsors">
			<div className="container-for mt-4">Thank you to our sponsors</div>
			<Row>
				<Col md={12}>
					<div className="mt-5">PLATINUM</div>
					<div className="sponsors-container margin">
						<div className="sponsor">
							<a
								href="https://www.asteronlife.co.nz/ "
								target="_blank"
								rel="noreferrer"
								title="Asteron Life"
							>
								<img
									src={getImageUrl('/assets/images/2021_sponsors/Asteron.jpg')}
								/>
							</a>
						</div>
						<div className="sponsor">
							<a
								href="https://www.dlapiper.com/en/newzealand"
								target="_blank"
								rel="noreferrer"
								title="DLA PIPER"
							>
								<img
									src={getImageUrl('/assets/images/2021_sponsors/DLA.jpg')}
								/>
							</a>
						</div>
						<div className="sponsor">
							<a
								href="https://solutions.jbhifi.com.au/solutions/"
								target="_blank"
								rel="noreferrer"
								title="JB HI-FI SOLUTIONS"
							>
								<img
									src={getImageUrl('/assets/images/sponsors/Solutions.jpg')}
								/>
							</a>
						</div>
						<div className="sponsor">
							<a
								href="https://www.manheim.com.au/"
								target="_blank"
								title="MANHEIM"
								rel="noreferrer"
							>
								<img
									src={getImageUrl('/assets/images/2021_sponsors/Manheim.jpg')}
								/>
							</a>
						</div>
						<div className="sponsor">
							<a
								href="https://www.nzi.co.nz/en.html"
								target="_blank"
								title="NZI"
								rel="noreferrer"
							>
								<img
									src={getImageUrl('/assets/images/2021_sponsors/NZI.png')}
								/>
							</a>
						</div>
						<div className="sponsor">
							<a
								href="https://www.vero.co.nz/"
								target="_blank"
								title="VERO"
								rel="noreferrer"
							>
								<img
									src={getImageUrl('/assets/images/2021_sponsors/Vero.jpg')}
								/>
							</a>
						</div>
					</div>
					<div className="mt-5">GOLD</div>
					<div className="sponsors-container margin">
						<div className="sponsor">
							<a
								href="https://www.sedgwick.com/solutions/global/nz "
								target="_blank"
								title="Sedgwick"
								rel="noreferrer"
							>
								<img
									src={getImageUrl('/assets/images/2021_sponsors/Sedgwick.jpg')}
								/>
							</a>
						</div>
					</div>
					<div className="mt-5">SCRUTINEER</div>
					<div className="sponsors-container margin">
						<div className="sponsor">
							<a
								href="https://www2.deloitte.com/nz/en.html "
								target="_blank"
								title="Deloitte"
								rel="noreferrer"
							>
								<img
									src={getImageUrl('/assets/images/2021_sponsors/Deloitte.png')}
								/>
							</a>
						</div>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default Sponsors;
