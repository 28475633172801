import React from 'react';
import { getImageUrl } from 'helper/helper';
import { Judges } from 'assets/data/old/static';

const OldJudge = () => {
	return (
		<div className="mt-5">
			<div className="title theme-color">JUDGES</div>
			<div className="judges-container mt-4">
				<div className="judges">
					{Judges.length > 0 &&
						Judges.map((data, index) => (
							<div className="judge old-judges" key={index}>
								<div className="judge-img">
									<img
										alt={data.name}
										src={getImageUrl(`/assets/images/judges/${data.photo}`)}
									/>
								</div>
								<div
									dangerouslySetInnerHTML={{
										__html: data.name,
									}}
									className="judge-name"
								></div>
							</div>
						))}
					<div className="judge old-judges">
						<div className="judge-img"></div>
						<div className="judge-name"></div>
					</div>
					<div className="judge old-judges">
						<div className="judge-img"></div>
						<div className="judge-name"></div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OldJudge;
