import React, { useState, useEffect, Suspense } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import Switch from 'react-switch';
import SideDrawer from './SideDrawer';
import { togglePosition } from 'selectors/system';
import AlertSignal from './Components/Alert/AlertSignal';
import { getSchemaName } from 'selectors/account';
import { sidebarToggler, videoToggler } from 'selectors/system';
import { pusherConfig } from 'helper/pusher';
import { getShowSideBarToggler, getVideoToggler } from 'selectors/session';
import { getPusherChannel } from 'config/Config';
import { toggleSidebar, toggleVideo } from 'actions/session';
import { getVideoPlayer } from 'actions/event';
import { ErrorToast } from 'components/ToastMessage/Toast';
import SystemInfo from './SystemInfo';
const Theo = React.lazy(() => import('./Theo'));
import { isLoggedIn } from 'selectors/auth';
const VideoJS = React.lazy(() => import('./Player/VideoJS'));
// const Millicast = React.lazy(() => import('./Player/Millicast'));

const Watch = (props) => {
	let {
		toggleVideoContainer,
		toggleSidebar,
		toggleVideo,
		show_sidebar_toggler,
		show_video_toggler,
		currentSidebarState,
		currentVideoState,
		getVideoPlayer,
		schema,
	} = props;
	let { id, slug } = useParams();
	let currentSidebar = currentSidebarState(id);
	let currentVideo = currentVideoState(id);
	let isLoggedIn = props.isLoggedIn(slug);

	if (!isLoggedIn) {
		props.history.push(`/`);
	}

	const initialState = {
		displayToggle: location.pathname.includes('/watch-now/'),
		toggle: toggleVideoContainer,
		show_sidebar_toggle: show_sidebar_toggler,
		show_video_toggle: show_video_toggler,
		currentSidebarState: currentSidebar,
		currentVideoState: currentVideo,
		loading: true,
		videoPlayer: true,
		question_panel: false,
		question_tab_label: '',
		session_info_label: '',
		poll_tab_label: '',
		all_question_tab: false,
		my_question_tab: false,
		ask_question_tab: false,
		moderation: false,
		question_description_caption: '',
		poll_status: false,
	};

	const [milliState, setMilliState] = useState({
		eventStream: '',
		streamName: '',
		accountId: '',
	});
	const [state, setState] = useState(initialState);
	const [alert, setAlert] = useState({ alerts: [] });

	const closeAlert = (alertId) => {
		if (alert.alerts) {
			let alertMsg = alert.alerts
				.map(function (item) {
					return item.id;
				})
				.indexOf(alertId);
			if (alertMsg > -1) {
				alert.alerts.splice(alertMsg, 1);
				setAlert({ ...alert, alerts: [...alert.alerts] });
			}
		}
	};

	useEffect(() => {
		const channel = pusherConfig().subscribe(getPusherChannel());
		channel.bind(`${schema}-send-alert`, (data) => {
			if (data.videoSessionId === parseInt(id)) {
				setAlert((prevState) => ({
					alerts: [...prevState.alerts, data],
				}));
			}
		});
		return () => channel.unbind(`${schema}-send-alert`);
	}, []);

	const callbackSucces = (data) => {
		const {
			video_player,
			question_panel,
			question_tab_label,
			session_info_label,
			agenda_info_label,
			poll_tab_label,
			all_question_tab,
			my_question_tab,
			ask_question_tab,
			agenda_tab,
			moderation,
			question_description_caption,
			poll_status,
		} = data;

		setState({
			...state,
			loading: false,
			videoPlayer: video_player,
			question_panel: question_panel,
			question_tab_label: question_tab_label,
			session_info_label: session_info_label,
			agenda_info_label: agenda_info_label,
			poll_tab_label: poll_tab_label,
			all_question_tab: all_question_tab,
			my_question_tab: my_question_tab,
			ask_question_tab: ask_question_tab,
			agenda_tab: agenda_tab,
			moderation: moderation,
			question_description_caption: question_description_caption,
			poll_status: poll_status,
		});
	};

	const callbackError = (e) => {
		setState({ ...state, loading: false });
		if (e.errorCode === 401) {
			ErrorToast(e.message, 3000);
			props.history.push(`/${slug}/info`);
		}
	};

	useEffect(() => {
		getVideoPlayer(id, slug, callbackSucces, callbackError);
	}, [id, slug]);

	const handleChange = (nextChecked) => {
		setState({
			...state,
			toggle: nextChecked,
			currentSidebarState: nextChecked,
		});
		toggleSidebar(id);
	};

	const handleVideoChange = (nextChecked) => {
		setState({
			...state,
			currentVideoState: nextChecked,
		});
		toggleVideo(id, nextChecked);
	};
	useEffect(() => {
		setState({
			...state,
			currentSidebarState: currentSidebar,
			currentVideoState: currentVideo,
		});
	}, [currentSidebar, currentVideo]);

	const changeStream = (stream) => {
		setMilliState({ ...milliState, eventStream: stream });
	};

	const changeMilliState = (dict) => {
		setMilliState({ ...milliState, ...dict });
	};
	return (
		<div className="theo-player-container mt-4">
			{state.displayToggle && (
				<Row>
					<Col>
						<div className="float-right toggle-switch">
							{show_sidebar_toggler && (
								<>
									<p className="sidebar-toggle">Sidebar</p>
									<Switch
										checked={currentSidebarState(id)}
										onChange={handleChange}
										onColor="#86d3ff"
										onHandleColor="#2693e6"
										handleDiameter={20}
										uncheckedIcon={false}
										checkedIcon={false}
										boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
										activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
										height={15}
										width={43}
										className="react-switch custom-switch"
										id="material-switch"
									/>
								</>
							)}

							{show_video_toggler && (
								<>
									<p className="video-label">Video</p>
									<Switch
										checked={currentVideoState(id)}
										onChange={handleVideoChange}
										onColor="#86d3ff"
										onHandleColor="#2693e6"
										handleDiameter={20}
										uncheckedIcon={false}
										checkedIcon={false}
										boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
										activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
										height={15}
										width={43}
										className="react-switch custom-switch"
										id="material-switch"
									/>
								</>
							)}
						</div>
						<br />
					</Col>
				</Row>
			)}
			<Row className="personal-card">
				<Col md={currentSidebarState(id) ? 7 : 12}>
					{alert.alerts &&
						alert.alerts.map(
							(alertMsg, index) =>
								alertMsg.alertPosition === 1 && (
									<AlertSignal key={index} data={alertMsg} close={closeAlert} />
								)
						)}

					{!state.loading && state.videoPlayer ? (
						state.videoPlayer === 1 ? (
							<Suspense fallback={<div></div>}>
								<Theo
									data={alert.alerts.filter(function (item) {
										return item.alertPosition === 2;
									})}
									close={closeAlert}
								/>
							</Suspense>
						) : state.videoPlayer === 2 ? (
							<Suspense fallback={<div></div>}>
								<VideoJS
									data={alert.alerts.filter(function (item) {
										return item.alertPosition === 2;
									})}
									close={closeAlert}
								/>
							</Suspense>
						) : (
							<Suspense fallback={<div></div>}>
								<VideoJS
									data={alert.alerts.filter(function (item) {
										return item.alertPosition === 2;
									})}
									close={closeAlert}
								/>
							</Suspense>
						)
					) : (
						''
					)}
				</Col>
				<Col
					md={currentSidebarState(id) ? 5 : 0}
					className={`${currentSidebarState(id) ? '' : 'hide'}`}
				>
					<SideDrawer
						displayQuestion={state.question_panel}
						questionLabel={state.question_tab_label}
						sessionLabel={state.session_info_label}
						agendaLabel={state.agenda_info_label}
						pollLabel={state.poll_tab_label}
						allQuestion={state.all_question_tab}
						myQuestion={state.my_question_tab}
						askQuestion={state.ask_question_tab}
						agendaTab={state.agenda_tab}
						moderation={state.moderation}
						question_description_caption={state.question_description_caption}
						pollStatus={state.poll_status}
					/>
				</Col>
			</Row>
			<SystemInfo />
			{alert.alerts &&
				alert.alerts.map(
					(alertMsg, index) =>
						alertMsg.alertPosition === 3 && (
							<AlertSignal key={index} data={alertMsg} close={closeAlert} />
						)
				)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		isLoggedIn: (slug) => isLoggedIn(state, slug),
		toggleVideoContainer: togglePosition(state),
		schema: getSchemaName(state),
		show_sidebar_toggler: sidebarToggler(state),
		show_video_toggler: videoToggler(state),
		currentSidebarState: (id) => getShowSideBarToggler(state, id),
		currentVideoState: (id) => getVideoToggler(state, id),
	};
};
const dispatchProps = {
	toggleSidebar,
	toggleVideo,
	getVideoPlayer,
};

export default connect(mapStateToProps, dispatchProps)(withRouter(Watch));
